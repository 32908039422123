import { render, staticRenderFns } from "./subordinate.vue?vue&type=template&id=51ede79a&scoped=true&"
import script from "./subordinate.vue?vue&type=script&lang=js&"
export * from "./subordinate.vue?vue&type=script&lang=js&"
import style0 from "./subordinate.vue?vue&type=style&index=0&id=51ede79a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ede79a",
  null
  
)

export default component.exports