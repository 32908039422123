<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">查看下级</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/Mypromotion' }">我的推广</el-breadcrumb-item>
          <el-breadcrumb-item>查看下级</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <div class="py-20 px-25 bg-EEEEEE flex justify-between font-14 mb-30">
        <div class="flex align-center">
          <!-- <img
            :src="avatar"
            width="3.3125rem"
            height="3.3125rem"
            class="circle mr-20"
            alt=""
          /> -->
          <el-image
            class="circle mr-20"
            style="width: 3.3125rem; height: 3.3125rem"
            :src="avatar"
            fit="cover"
          ></el-image>
          <div>
            <div class="mb-12">
              {{ remark ? remark : username }}
            </div>
            <div class="text-B5B5B5">VIP时间： {{ vip_start_time }}</div>
          </div>
        </div>
        <div class="text-999999">用户ID{{ uid }}</div>
      </div>
      <!-- 标题 -->
      <div class="pb-15 border-bottom border-EEEEEE font-14 flex align-center">
        <div class="font-weight-bolder mr-30">{{ remark ? remark : username }}下级</div>
        <div class="text-E7524C">他的下级人数：{{ child_one_nums }}人</div>
      </div>
      <!--内容  -->
      <div>
        <div
          class="border-bottom border-EEEEEE flex justify-between pt-35 pb-25"
          v-for="(item, index) in info"
          :key="index"
        >
          <div class="flex align-center">
            <!-- <img
              :src="item.avatar"
              width="3.3125rem"
              height="3.3125rem"
              class="circle mr-20"
              alt=""
            /> -->
            <el-image
              class="circle mr-20"
              style="width: 3.3125rem; height: 3.3125rem"
              :src="item.avatar"
              fit="cover"
            ></el-image>
            <div class="font-14">
              <div class="mb-10">
                {{ item.username }}
              </div>
              <div class="text-B5B5B5">
                VIP时间：<span v-if="item.vip_start_time">{{ item.vip_start_time }}</span
                ><span v-if="item.vip_start_time && item.vip_end_time">~</span
                ><span v-if="item.vip_end_time">{{ item.vip_end_time }}</span>
              </div>
            </div>
          </div>
          <div class="font-14 text-999999">用户ID{{ item.id }}</div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-count="page_nums"
          @current-change="currentChange"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      uid: "",
      avatar: "",
      username: "",
      remark: "",
      vip_start_time: "",
      info: [],
      page: 1,
      page_nums: 0,
      subordinateid: 0,
      child_one_nums: 0,
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let subordinateid = this.$route.query.subordinateid;
        if (subordinateid) {
          this.subordinateid = subordinateid;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let subordinateid = this.$route.query.subordinateid;
    if (subordinateid) {
      this.subordinateid = subordinateid;
      this.getdata();
    }
  },
  methods: {
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/findChild", {
          id: that.subordinateid,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.avatar = res.data.avatar;
          that.child_one_nums = res.data.child_one_nums;

          that.username = res.data.username;
          that.remark = res.data.remark;
          that.vip_start_time = res.data.vip_start_time;
          that.uid = res.data.id;
          that.info = res.data.child_list.data;
          that.page_nums = res.data.child_list.last_page;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
